<template>
  <div>
    <portal to="page-top-title">New Sender</portal>
    <portal to="page-top-right"/>

    <box-form action="create" data-prop="formData" url="/senders" redirect="senders-list" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" :min="3" :max="64" required/>
          </column-input>
          <column-input>
            <form-group-select name="category_id" title="Category" :model="formData.category_id"
                               :options="categories" :disabled="!categories.length" required/>
          </column-input>
          <column-input :md="2">
            <form-group-check name="active" :model="formData.active"/>
          </column-input>
        </row>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import FormGroupSelect from '@/views/components/form/FormGroupSelect'
import Form from '@/modules/utils/Form'

export default {
  name: 'SendersNew',
  metaInfo () {
    return { title: 'Senders | New' }
  },
  components: {
    BoxForm,
    FormGroupCheck,
    FormGroupInput,
    FormGroupSelect
  },
  data () {
    return {
      categories: [],
      formData: {
        category_id: Form.types.select(),
        name: Form.types.input(),
        active: Form.types.boolean(true)
      }
    }
  },
  created () {
    this.loadCategories()
  },
  methods: {
    loadCategories () {
      this.$http
        .get('/senders/categories', { params: { no_pages: 1 } })
        .then((res) => {
          const { data: { data } } = res

          this.categories = data.map((category) => { return { id: category.id, name: category.name } })

          this.categoryCheckSelected()
        })
    },
    categoryCheckSelected () {
      const queryCatId = Number(this.$route.query.category)

      if (isNaN(queryCatId)) return

      const category = this.categories.find((category) => category.id === queryCatId)

      if (category) this.formData.category_id = Form.types.select(category)
    }
  }
}
</script>
